
nav.main a {
  display: block;
  text-decoration: none;
  color: #626469;
  font-weight: bold;
  padding: 5px 10px 0;
  transition: 0.2s;
}

nav.main a:hover {
  transform: translateY(2px);
}

nav.main a.active {
  border-bottom: 2px #ec008c solid;
  color: #ec008c;
  transform: translateY(0);
}
.logo {
  width: 100px;
  height: 40px;
}
.header {
  display: flex;
  width: 100%;
  padding: 15px 0;
}

.main {
  width: 100%;
}

@media (min-width: 768px) {
  .logo {
    width: 140px;
    height: 50px;
  }
  .main ul {
    justify-content: flex-end;
  }
}
