.link {
  text-align: center;
  padding: 40px 0 10px
}
.link > a {
  color: #626469;
  font-weight: bold;
  font-size: 12px;
}
@media (min-width: 1000px) {
  .link > a {
    font-size: 17px;
  }
  .link {
    padding: 80px 0 20px
  }
}
