.text,
.textShareholders {
  padding: 0 10%;
  color: #626469;
  font-size: 15px;
  text-align: justify;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  width: 40%;
  width: fit-content;
  padding-top: 3%;
  margin-bottom: 3%;
  border-bottom: 2px #ec008c solid;
}
.description {
  padding: 20px 0;
}
.firstAcapit {
  padding: 0 0 20px;
}
.flex {
  display: block;
  justify-content: space-around;
}
.contact {
  color: #626469;
  text-decoration: none;
}
.titleContainer {
  text-align: -webkit-center;
}
span {
  font-weight: bold;
}
.logoContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-top: 20px;
}
.companyLogo {
  width: 100px;
}
.logoInvest {
  height: 70%;
  margin-top: 10px;
}
.text > p:nth-child(n + 9):nth-child(-n + 11) {
  padding-bottom: 0px;
}

.text > p:nth-child(n + 14):nth-child(-n + 16) {
  padding-bottom: 0px;
}

.textShareholders > p:nth-child(n + 9):nth-child(-n + 14) {
  padding-bottom: 0px;
}

.textShareholders > p:nth-child(n + 16):nth-child(-n + 17) {
  padding-bottom: 0px;
}

.textShareholders > p:nth-child(n + 19):nth-child(-n + 23) {
  padding-bottom: 0px;
}

.info > p:nth-child(7) {
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
  border-top: 2px outset #626469;
  padding-top: 20px;
}

.info > p:nth-child(3) {
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
  border-top: 2px outset #626469;
  padding-top: 20px;
}

@media (min-width: 500px) {
  .title {
    padding-top: 3%;
    margin-bottom: 5%;
    font-size: 25px;
  }
}
@media (min-width: 800px) {
  .flex {
    display: flex;
  }
  .companyLogo {
    width: 150px;
  }
  .name {
    display: flex;
    justify-content: space-around;
  }
  .name > p {
    width: 40%;
  }
}

@media (min-width: 1000px) {
  .text,
  .textShareholders {
    font-size: 20px;
  }
  .title {
    font-size: 35px;
    border-bottom: 4px #ec008c solid;
  }
  .description {
    padding: 40px 0;
  }
  .firstAcapit {
    padding: 0 0 40px;
  }
  .companyLogo {
    width: 180px;
  }
  .logoContainer {
    padding-top: 40px;
  }
  .info > p:nth-child(7) {
    font-size: 16px;
    padding-top: 40px;
  }

  .info > p:nth-child(3) {
    font-size: 16px;
    padding-top: 40px;
  }
}
