* {
  margin: 0;
  font-family: Segoe UI;
}
div.app {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  color: #222;
}

main {
  display: flex;
  align-self: center;
  flex-grow: 1;
}

section.page {
  flex-grow: 1;
  z-index: 100;
}

@media (min-width: 1000px) {
  div.app {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  div.app {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  div.app {
    width: 60%;
  }
}

header img {
  width: 100%;
}


nav.main a {
  display: block;
  text-decoration: none;
  color: #626469;
  font-weight: bold;
  padding: 5px 10px 0;
  transition: 0.2s;
}

nav.main a:hover {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

nav.main a.active {
  border-bottom: 2px #ec008c solid;
  color: #ec008c;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.logo {
  width: 100px;
  height: 40px;
}
.header {
  display: flex;
  width: 100%;
  padding: 15px 0;
}

.main {
  width: 100%;
}

@media (min-width: 768px) {
  .logo {
    width: 140px;
    height: 50px;
  }
  .main ul {
    justify-content: flex-end;
  }
}

.card {
  width: 90%;
  box-shadow: 0 5px 25px #95989a;
  margin: 0 auto;
  background-color: white;
  margin-top: -12%;
  z-index:1000;
}
@media (min-width: 700px) {
  .card {
    margin-top: -10%;
  }
}

.link {
  text-align: center;
  padding: 40px 0 10px
}
.link > a {
  color: #626469;
  font-weight: bold;
  font-size: 12px;
}
@media (min-width: 1000px) {
  .link > a {
    font-size: 17px;
  }
  .link {
    padding: 80px 0 20px
  }
}

.text,
.textShareholders {
  padding: 0 10%;
  color: #626469;
  font-size: 15px;
  text-align: justify;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  width: 40%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 3%;
  margin-bottom: 3%;
  border-bottom: 2px #ec008c solid;
}
.description {
  padding: 20px 0;
}
.firstAcapit {
  padding: 0 0 20px;
}
.flex {
  display: block;
  justify-content: space-around;
}
.contact {
  color: #626469;
  text-decoration: none;
}
.titleContainer {
  text-align: -webkit-center;
}
span {
  font-weight: bold;
}
.logoContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-top: 20px;
}
.companyLogo {
  width: 100px;
}
.logoInvest {
  height: 70%;
  margin-top: 10px;
}
.text > p:nth-child(n + 9):nth-child(-n + 11) {
  padding-bottom: 0px;
}

.text > p:nth-child(n + 14):nth-child(-n + 16) {
  padding-bottom: 0px;
}

.textShareholders > p:nth-child(n + 9):nth-child(-n + 14) {
  padding-bottom: 0px;
}

.textShareholders > p:nth-child(n + 16):nth-child(-n + 17) {
  padding-bottom: 0px;
}

.textShareholders > p:nth-child(n + 19):nth-child(-n + 23) {
  padding-bottom: 0px;
}

.info > p:nth-child(7) {
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
  border-top: 2px outset #626469;
  padding-top: 20px;
}

.info > p:nth-child(3) {
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
  border-top: 2px outset #626469;
  padding-top: 20px;
}

@media (min-width: 500px) {
  .title {
    padding-top: 3%;
    margin-bottom: 5%;
    font-size: 25px;
  }
}
@media (min-width: 800px) {
  .flex {
    display: flex;
  }
  .companyLogo {
    width: 150px;
  }
  .name {
    display: flex;
    justify-content: space-around;
  }
  .name > p {
    width: 40%;
  }
}

@media (min-width: 1000px) {
  .text,
  .textShareholders {
    font-size: 20px;
  }
  .title {
    font-size: 35px;
    border-bottom: 4px #ec008c solid;
  }
  .description {
    padding: 40px 0;
  }
  .firstAcapit {
    padding: 0 0 40px;
  }
  .companyLogo {
    width: 180px;
  }
  .logoContainer {
    padding-top: 40px;
  }
  .info > p:nth-child(7) {
    font-size: 16px;
    padding-top: 40px;
  }

  .info > p:nth-child(3) {
    font-size: 16px;
    padding-top: 40px;
  }
}

.footer {
  text-align: center;
  color: #626469;
  font-weight: bold;
  font-size: 10px;
  padding: 5px 0;
}

@media (min-width: 1000px) {
  .footer {
    font-size: 15px;
    padding: 10px 0;
  }
}

