.card {
  width: 90%;
  box-shadow: 0 5px 25px #95989a;
  margin: 0 auto;
  background-color: white;
  margin-top: -12%;
  z-index:1000;
}
@media (min-width: 700px) {
  .card {
    margin-top: -10%;
  }
}
