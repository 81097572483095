.footer {
  text-align: center;
  color: #626469;
  font-weight: bold;
  font-size: 10px;
  padding: 5px 0;
}

@media (min-width: 1000px) {
  .footer {
    font-size: 15px;
    padding: 10px 0;
  }
}
