* {
  margin: 0;
  font-family: Segoe UI;
}
div.app {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  color: #222;
}

main {
  display: flex;
  align-self: center;
  flex-grow: 1;
}

section.page {
  flex-grow: 1;
  z-index: 100;
}

@media (min-width: 1000px) {
  div.app {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  div.app {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  div.app {
    width: 60%;
  }
}
